@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
@media only screen and (max-width: 850px) {
    .landingPageBody {
      height: 100vh;
      width: 100%;
      background-color: #fff;
    }
    .logo {
      width: 76%;
      display: block;
      margin: auto;
    }
    .image-beta {
      opacity: 1;
      position: absolute;
      top: 25%;
      width: 100%;
      -webkit-animation: 1s ease-out 0s 1 appear1;
              animation: 1s ease-out 0s 1 appear1;
    }
    .password_form {
      opacity: 1;
      position: absolute;
      top:50%;
      width: 100%;
      -webkit-animation: 2s ease-out 0s 1 appear2;
              animation: 2s ease-out 0s 1 appear2;
    }
    .password_form form {
      width: 300px;
      margin: auto;
    }
    .password_form input {
      width: 100%;
      height: 30px;
      margin-bottom: 10px;
      border-radius: 5px;
      font-size: 14px;
      padding: 0 10px;
      box-sizing: border-box;
      border:1px solid lightgray !important;
    }
    .password_form label {
      display: block;
      width: 100px;
      margin: 0 auto -10px;
      font-size: 18px;
    }
    .password_form button {
      width: 100%;
      height: 30px;
      border:none;
      background-color: #ea556f;
      color: white;
      border-radius: 5px;
      font-size: 16px;
    }
    .password_form button:hover {
      background-color: #c13850;
      cursor: pointer;
    }
    .landingPageBody h1 {
      line-height: 36px;
    }
  }
  @media only screen and (min-width: 850px) {
  .landingPageBody {
    height: 100vh;
    background-color: #fff;
  }
  .logo {
    width: 400px;
    display: block;
    margin: auto;
  }
  .image-beta {
    opacity: 1;
    position: absolute;
    top: 35%;
    width: 100%;
    -webkit-animation: 1s ease-out 0s 1 appear1;
            animation: 1s ease-out 0s 1 appear1;
  }
  .password_form {
    opacity: 1;
    position: absolute;
    top:50%;
    width: 100%;
    min-width: 645px;
    -webkit-animation: 2s ease-out 0s 1 appear2;
            animation: 2s ease-out 0s 1 appear2;
  }
  .password_form form {
    width: 300px;
    margin: auto;
  }
  .password_form input {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 14px;
    padding: 0 10px;
    box-sizing: border-box;
    border:1px solid lightgray !important;
  }
  .password_form label {
    display: block;
    width: 100px;
    margin: 0 auto -10px;
    font-size: 18px;
  }
  .password_form button {
    width: 100%;
    height: 30px;
    border:none;
    background-color: #ea556f;
    color: white;
    border-radius: 5px;
    font-size: 16px;
  }
  .password_form button:hover {
    background-color: #c13850;
    cursor: pointer;
  }
  }
  @-webkit-keyframes appear1 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear1 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear2_2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear2_2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear2_3 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear2_3 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear2_4 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear2_4 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear3 {
    0% {
      opacity: 0;
    }
    67% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear3 {
    0% {
      opacity: 0;
    }
    67% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear3_2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear3_2 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear3_3 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear3_3 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes appear3_4 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes appear3_4 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .landingPageBody h1 {
    top: 25%;
    font-size: 36px;
    width: 100%;
    position: absolute;
    color: white;
    text-align: center;
  }
  .first_header {
    -webkit-animation: 1s ease-out 0s 1 appear1;
            animation: 1s ease-out 0s 1 appear1;
  }
  .information {
    top: 45%;
    font-size: 20px;
    width: 80%;
    margin-left: 10%;
    position: absolute;
    color: white;
    text-align: center;
  }
  .information_text {
    -webkit-animation: 2s ease-out 0s 1 appear2;
            animation: 2s ease-out 0s 1 appear2;
  }
  .information_text2 {
    -webkit-animation: 1s ease-out 0s 1 appear2_2;
            animation: 1s ease-out 0s 1 appear2_2;
  }
  .information_text3 {
    -webkit-animation: 1s ease-out 0s 1 appear2_3;
            animation: 1s ease-out 0s 1 appear2_3;
  }
  .information_text4 {
    -webkit-animation: 1s ease-out 0s 1 appear2_4;
            animation: 1s ease-out 0s 1 appear2_4;
  }
  .next_page {
    position: absolute;
    top: 65%;
    width: 100%;
    text-align: center;
  }
  .next_page_button {
    font-size: 24px;
    color:white;
    background-color: #ea556f;
    padding: 5px 20px;
    border-radius: 5px;
    -webkit-animation: 3s ease-out 0s 1 appear3;
            animation: 3s ease-out 0s 1 appear3;
  }
  .next_page_button:hover{
    background-color: #c13850;
    cursor: pointer;
  }
  .next_page_button2 {
    font-size: 24px;
    color:white;
    background-color: #ea556f;
    padding: 5px 20px;
    border-radius: 5px;
    -webkit-animation: 2s ease-out 0s 1 appear3_2;
            animation: 2s ease-out 0s 1 appear3_2;
  }
  .next_page_button2:hover{
    background-color: #c13850;
    cursor: pointer;
  }
  .next_page_button3 {
    font-size: 24px;
    color:white;
    background-color: #ea556f;
    padding: 5px 20px;
    border-radius: 5px;
    -webkit-animation: 2s ease-out 0s 1 appear3_3;
            animation: 2s ease-out 0s 1 appear3_3;
  }
  .next_page_button3:hover{
    background-color: #c13850;
    cursor: pointer;
  }
  .next_page_button4 {
    font-size: 24px;
    color:white;
    background-color: #ea556f;
    padding: 5px 20px;
    border-radius: 5px;
    -webkit-animation: 2s ease-out 0s 1 appear3_4;
            animation: 2s ease-out 0s 1 appear3_4;
  }
  .next_page_button4:hover{
    background-color: #c13850;
    cursor: pointer;
  }
  .unauthorized_landing {
    text-align: center;
    color: red;
    margin-top: 10px;
    margin-bottom: -10px;
  }
  
body {
  margin: 0 0 400px;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #f0f2f5;
}
input, select, textarea{font-family: 'Open Sans', sans-serif;}


.item span.channel {
  color: #aaa;
  font-size: 13px;
}

.loading,
.nomore {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}





.newsletter-date {
  margin-top: 0px;
  color: #969799;
  font-size: 12px;
  font-weight: 300;
}

.merchant_title:hover {
  cursor: pointer;
  color: #ea556f;
}
.more-from-merchant-body {
  background-color: white;
  border-top: 1px #edeff1 solid;
  height: 100%;
  overflow: auto;
}
.more-from-merchant-body-s {
  height: 100px;
}
.more-from-merchant-body-m {
  height: 200px;
}
.more-from-merchant-body-l {
  height: 300px;
}
h1 {
  font-size: 22px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 26.4px;
  margin-top: 5px;
  margin-bottom: 0px;
  padding-top: 0;
  display: inline;
}
h2 {
  font-size: 18px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 18x;
  margin-bottom: 12px;
  margin-top: 12px;
  overflow: auto;
}
h3 {
  font-size: 16px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 18x;
  margin-bottom: 12px;
  margin-top: 12px;
  overflow: auto;
}
h4 {
  font-size: 12px;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 15.4px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.mer-scoop {
  padding: 5px 5px 5px;
  border-bottom: 1px solid #edeff1;
  height: 70px;
}
.ms-unselected {
  background-color: white;
}
.ms-selected {
  background-color: #f0f2f5;
}
.ms-unselected:hover {
  cursor: pointer;
}
.mer-scoop-image {
  float:left;
  margin-right: 10px;
  object-fit: contain;
  width: 70px;
  height: 70px;
  background-color: white;
}
.mer-scoop-date {
  margin-top: 0px;
  color: #969799;
  text-align: right;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 300;
}
.mer-scoop-title {
  font-size: 14px;
  margin-top: 2px;
}
