@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
body {
  margin: 0 0 400px;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #f0f2f5;
}
input, select, textarea{font-family: 'Open Sans', sans-serif;}


.item span.channel {
  color: #aaa;
  font-size: 13px;
}

.loading,
.nomore {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}





.newsletter-date {
  margin-top: 0px;
  color: #969799;
  font-size: 12px;
  font-weight: 300;
}

.merchant_title:hover {
  cursor: pointer;
  color: #ea556f;
}
.more-from-merchant-body {
  background-color: white;
  border-top: 1px #edeff1 solid;
  height: 100%;
  overflow: auto;
}
.more-from-merchant-body-s {
  height: 100px;
}
.more-from-merchant-body-m {
  height: 200px;
}
.more-from-merchant-body-l {
  height: 300px;
}
h1 {
  font-size: 22px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 26.4px;
  margin-top: 5px;
  margin-bottom: 0px;
  padding-top: 0;
  display: inline;
}
h2 {
  font-size: 18px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 18x;
  margin-bottom: 12px;
  margin-top: 12px;
  overflow: auto;
}
h3 {
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 18x;
  margin-bottom: 12px;
  margin-top: 12px;
  overflow: auto;
}
h4 {
  font-size: 12px;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  line-height: 15.4px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.mer-scoop {
  padding: 5px 5px 5px;
  border-bottom: 1px solid #edeff1;
  height: 70px;
}
.ms-unselected {
  background-color: white;
}
.ms-selected {
  background-color: #f0f2f5;
}
.ms-unselected:hover {
  cursor: pointer;
}
.mer-scoop-image {
  float:left;
  margin-right: 10px;
  object-fit: contain;
  width: 70px;
  height: 70px;
  background-color: white;
}
.mer-scoop-date {
  margin-top: 0px;
  color: #969799;
  text-align: right;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 300;
}
.mer-scoop-title {
  font-size: 14px;
  margin-top: 2px;
}